<!-- 导航栏 -->
<template>
  <div class="container">
    <div class="nav">
      <!-- logo -->
      <div class="nav-left">
        <img src="@/assets/home/logo.png" alt="" />
      </div>

      <div class="nav-right">
        <!-- 页面title -->
        <div
          class="nav_list"
          v-for="(item, index) in navList"
          :key="index"
          @click="goLink(index, item.path)"
          :style="{
            color: indexNum === item.id ? '#E97D29' : '#373737',
            fontFamily:
              indexNum === item.id
                ? 'Poppins-Bold, Poppins'
                : 'Poppins-SemiBold, Poppins',
          }"
        >
          <span>
            {{ item.name }}
          </span>
        </div>
        <!-- 右边滑动样式 -->
        <div
          id="slide_button"
          @mouseover="overFn"
          @mouseleave="leaveFn"
          class="hvr-sweep-to-right"
          @click="goLink(5, '/inquire/index')"
        >
          <span :style="{ fontSize: text == 'Discover' ? '20px' : '14px' }">
            {{ text }}
          </span>
        </div>
      </div>
    </div>
    <div class="_nav"></div>
    <div class="contend">
      <router-view></router-view>
      <!-- <keep-alive :include="['Home']"> </keep-alive> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexNum: 1,
      navList: [
        {
          id: 1,
          name: "Home",
          path: "/home/index",
        },
        {
          id: 2,
          name: "Services",
          path: "/services/index",
        },
        {
          id: 3,
          name: "Partners",
          path: "/partners/index",
        },

        {
          id: 4,
          name: "About Us",
          path: "/about/index",
        },
        {
          id: 5,
          name: "Privacy Policy",
          path: "/policy/index",
        },
        {
          id: 6,
          name: "",
          path: "/inquire/index",
        },
      ],
      text: "Discover",
    };
  },

  methods: {
    goLink(index, path) {
      this.indexNum = index + 1;
      this.$router.push({ path });
    },
    overFn() {
      this.text = "Become our valued parenter";
    },

    leaveFn() {
      this.text = "Discover";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .nav {
    width: 100%;
    height: 1.04rem;
    display: flex;
    align-items: center;
    z-index: 999;
    top: 0;
    position: fixed;

    background: #ffffff;
    .nav-left {
      display: flex;
      align-items: center;
      height: 0.32rem;
      margin-left: 1.38rem;
      img {
        height: 0.63rem;
        width: 1.34rpx;
      }
    }
    .nav-right {
      cursor: pointer;
      margin-left: 0.96rem;
      height: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0.6rem;
        font-size: 0.29rem;
        color: #373737;
        cursor: pointer;
        margin-top: 0.04rem;
        font-weight: bold;
      }
      .hvr-sweep-to-right {
        text-align: center;
        width: 2.94rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.21rem;

        background: #716e6d;
        border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
        font-family: Poppins-SemiBold, Poppins;
        font-weight: 600;
        color: #ffffff;
        margin-left: 0.23rem;
      }
      .hvr-sweep-to-right {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
      }
      .hvr-sweep-to-right:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #d75b25;
        border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      .hvr-sweep-to-right:hover,
      .hvr-sweep-to-right:focus,
      .hvr-sweep-to-right:active {
        color: white;
      }
      .hvr-sweep-to-right:hover:before,
      .hvr-sweep-to-right:focus:before,
      .hvr-sweep-to-right:active:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
  ._nav {
    height: 1.04rem;
  }
  .contend {
    position: relative;
  }
}
.link {
  color: #ffffff;
}
</style>
