import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);
//创建并暴露一个路由器
const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    redirect: "/home/index",
    children: [
      {
        path: "/home/index",
        name: "Home",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/services/index",
        name: "Services",
        component: () => import("@/views/services/index.vue"),
      },
      {
        path: "/partners/index",
        name: "Partners",
        component: () => import("@/views/partners/index.vue"),
      },
      {
        path: "/about/index",
        name: "About",
        component: () => import("@/views/about/index.vue"),
      },
      {
        path: "/policy/index",
        name: "Policy",
        component: () => import("@/views/policy/index.vue"),
      },
      {
        path: "/inquire/index",
        name: "Inquire",
        component: () => import("@/views/inquire/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes,
  scrollBehavior() {
    //函数拥有 to和from参数
    //可以通过 return {x:0,y:0} 来控制滚动条
    //console.log(arguments)
    return { x: 0, y: 0 };
  },
});

export default router;
