(function (doc, win) {
  var docEl = doc.documentElement,
    pW = docEl.clientWidth > 750 ? 1920 : 750;

  // 100这里的100就是rem换算 1rem = 100px.  1920px/100px = 19.2rem
  getRem(pW, 100);
  window.onresize = function () {
    getRem(pW, 100);
  };
  function getRem(pwidth, prem) {
    var html = document.getElementsByTagName("html")[0];
    var oWidth =
      document.body.clientWidth || document.documentElement.clientWidth;
    html.style.fontSize = (oWidth / pwidth) * prem + "px";
  }
})(document, window);
