<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import HomeVue from './views/home/Home.vue'

export default {
  name: 'App'
  // components: {
  //   HomeVue
  // }
}
</script>

<style>
</style>
